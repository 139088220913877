<template>
  <div class="container">
    <!-- Header Row -->
    <div class="row bg-white p-1 mb-2">
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <p style="font-size: 1.3rem; margin-top: 15px;">Bank Video</p>
        <button type="button" class="btn btn-outline-primary ml-auto mt-1" @click="navigateToAdd">
          <i class="fa-solid fa-plus"></i> Tambah
        </button>
      </div>

      <!-- Controls Row -->
      <div class="col-md-12 d-flex justify-content-between align-items-center mt-2">
        <!-- Per Page Select -->
        <b-form-group class="mb-0 d-flex align-items-center">
          <label class="d-inline-block text-sm-left mr-2">Per page</label>
          <b-form-select id="perPageSelect" v-model="perPage" :options="pageOptions" class="w-auto" />
        </b-form-group>

        <!-- Sort Controls -->
        <b-form-group class="mb-0 d-flex align-items-center">
          <label class="d-inline-block text-sm-left mr-2">Sort</label>
          <b-input-group size="sm" class="w-auto">
            <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" class="w-50">
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-50">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- Search Input -->
        <b-form-group class="mb-0 d-flex align-items-center">
          <label class="d-inline-block text-sm-left mr-2">Search</label>
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Search..." class="w-75" />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="clearFilter">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>

      <!-- Table -->
      <div class="col-md-12 mt-2">
        <b-table striped hover responsive :fields="fields" :items="filteredItems" :per-page="perPage"
          :current-page="currentPage">
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(judul)="data">
            {{ data.item.title }}
          </template>
          <template #cell(deskripsi)="data">
            {{ data.item.description }}
          </template>
          <template #cell(durasiwaktu)="data">
            {{ data.item.minustes }}
          </template>
          <template #cell(video)="data">
            <video :src="data.item.file" controls width="200"></video>
          </template>
          <template #cell(action)="row">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
              <template v-slot:button-content>
                <i class="fa-solid fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item @click="editItem(row.item)">
                Edit
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(row.item)">
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
          class="my-0" />
      </div>
    </div>
  </div>
</template>


<script>
import {
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BButton,
  BFormSelect
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BFormSelect
  },
  data() {
    return {
      fields: [
        { key: "index", label: "No" },
        { key: "judul", label: "Judul" },
        { key: "deskripsi", label: "Deskripsi" },
        { key: "durasiwaktu", label: "Durasi Waktu" },
        { key: "video", label: "Video" },
        { key: "action", label: "Aksi" },
      ],
      perPage: 5,
      currentPage: 1,
      filter: '',
      sortBy: '',
      sortDesc: false,
      pageOptions: [5, 10, 15, 20],
      sortOptions: [
        { value: 'title', text: 'Judul' },
        { value: 'description', text: 'Deskripsi' },
        { value: 'minustes', text: 'Durasi Waktu' },
        { value: 'file', text: 'Video' },
      ],
    };
  },
  computed: {
    ...mapGetters('bankvideo', ['getVideos']),
    filteredItems() {
      let items = this.getVideos;
      if (!this.filter) {
        return items;
      }
      return items.filter(item =>
        item.title.toLowerCase().includes(this.filter.toLowerCase()) ||
        item.description.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
    totalRows() {
      return this.filteredItems.length;
    }
  },
  methods: {
    ...mapActions('bankvideo', ['getBankVideo']),
    navigateToAdd() {
      this.$router.push({ name: 'admin-bank-video-add' });
    },
    clearFilter() {
      this.filter = '';
    },
    editItem(item) {
      console.log("Edit item", item);
    },
    deleteItem(item) {
      console.log("Delete item", item);
    }
  },
  mounted() {
    this.getBankVideo(); 
  }
};
</script>
